<template>
  <el-tabs class="bg-shop-summary statistics-shop-active" type="card" @tab-click="tabChange">
    <el-tab-pane label="订单数">

      <el-card shadow="hover" class="x-card-title-slot mb-3">
        <div class="row no-gutters align-items-center" slot="header">
          <div class="col-auto header-title">
            新增订单数
          </div>

          <el-date-picker
            style="width: 220px;"
            class="mr-3"
            size="medium"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          />

          <el-button type="primary" size="small" @click="GET_PriceStatistics()">搜索</el-button>

          <div class="col"></div>

          <el-button type="text" :loading="importLoading2" @click="downZd1()">导出当前数据</el-button>
        </div>

        <div id="orderTb" :style="{height: '60vh'}"></div>
      </el-card>

      <el-card shadow="hover" class="x-card-title-slot mb-3">
        <div class="row no-gutters align-items-center" slot="header">
          <div class="col-auto header-title">
            TOP订单数
          </div>
          <el-date-picker
            style="width: 220px"
            class="mr-3"
            size="medium"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate1"
            type="daterange"
            @change="daterangeDateChange1"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>

          <el-button type="primary" size="small" @click="GET_PriceStatistics1()">搜索</el-button>
        </div>

        <div class="row no-gutters align-items-center" style="height: 60vh">
          <div class="col" style="height: 100%;width:45%">
            <div id="orderPie" style="height: 100%;"></div>
          </div>
          <div class="col" style="height: 100%;width:45%">
            <en-table-layout
              :toolbar="false"
              :tableData="pageData.data"
            >
              <template slot="table-columns">
                <el-table-column label="排行">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>

                <el-table-column width="200" prop="goods_name" label="名称"></el-table-column>

                <!-- <el-table-column prop="enterprise_price" label="分销价"></el-table-column> -->

                <el-table-column prop="goods_num" label="数量"></el-table-column>
                <el-table-column prop="order_num" label="订单数"></el-table-column>

                <!-- <el-table-column label="金额总值">
                  <template slot-scope="scope">
                    {{ scope.row.enterprise_price * scope.row.goods_num }}
                  </template>
                </el-table-column> -->
              </template>
            </en-table-layout>
          </div>
        </div>
      </el-card>
    </el-tab-pane>

    <el-tab-pane label="消费金额" lazy>
      <el-card shadow="hover" class="x-card-title-slot mb-3">
        <div class="row no-gutters align-items-center" slot="header">
          <div class="col-auto header-title">
            消耗金额数
          </div>

          <el-date-picker
            style="width: 220px;"
            class="mr-3"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate2"
            type="daterange"
            size="medium"
            @change="daterangeDateChange2"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>

          <el-button type="primary" size="small" @click="GET_PriceStatistics2()">搜索</el-button>

          <div class="col"></div>

          <el-button type="text" :loading="importLoading2" @click="downZd1()">导出当前数据</el-button>
        </div>

        <div id="moneyTb" :style="{height: '60vh'}"></div>
      </el-card>

      <el-card shadow="hover" class="x-card-title-slot mb-3">
        <div class="row no-gutters align-items-center" slot="header">
          <div class="col-auto header-title">
            TOP金额消耗
          </div>

          <el-date-picker
            style="width: 220px"
            class="mr-3"
            size="medium"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate3"
            type="daterange"
            @change="daterangeDateChange3"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>

          <el-button type="primary" size="small" @click="GET_PriceStatistics3()">搜索</el-button>
        </div>

        <div class="row no-gutters align-items-center" style="height: 60vh">
          <div class="col" style="height: 100%;width:45%">
            <div id="moneyPie" style="height: 100%"></div>
          </div>
          <div class="col" style="height: 100%;width:45%">
            <en-table-layout
              :toolbar="false"
              :tableData="pageData2.data"
            >
              <template slot="table-columns">
                <el-table-column label="排行">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>

                <el-table-column width="200" prop="goods_name" label="名称"></el-table-column>

                <el-table-column prop="shop_price" label="分销价"></el-table-column>

                <el-table-column prop="goods_num" label="数量"></el-table-column>

                <el-table-column prop="total_money" label="金额总值"></el-table-column>
              </template>
            </en-table-layout>
          </div>
        </div>
      </el-card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import * as API_buyAnyalysis from '@/api/buyAnyalysis'
import {CategoryPicker} from '@/components'
import {handleDownload} from '@/utils'
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'buyAnalysis',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      orderTb: null,
      orderPie: null,
      moneyTb: null,
      moneyPie: null,
      type: 1,
      typeList: [
        {
          label: '订单数',
          value: 1
        },
        {
          label: '消耗金额',
          value: 2
        },
      ],
      pageData: {data: []},
      pageData2: {data: []},
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
            start.setTime(start.getTime());
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近7日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近15日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近30日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      importLoading: false,
      importLoading1: false,
      importLoading2: false,

      daterangeDate: '',
      daterangeDate1: '',
      daterangeDate2: '',
      daterangeDate3: '',

      /** 图表参数*/
      params: {},
      params1: {},
      params2: {},
      params3: {},

      data: '',
      data1: '',

      tableHeight: document.body.clientHeight / 2
    }
  },
  mounted() {
    this.tabChange({label: '订单数'});
  },
  methods: {
    resetTimeParams(type) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      if (type === 1 || type === undefined) {
        this.params.start_time = parseInt(start / 1000);
        this.params.end_time = parseInt(end / 1000);
      }

      if (type === 2 || type === undefined) {
        this.params1.start_time = parseInt(start / 1000);
        this.params1.end_time = parseInt(end / 1000);
      }

      if (type === 3 || type === undefined) {
        this.params2.start_time = parseInt(start / 1000);
        this.params2.end_time = parseInt(end / 1000);
      }

      if (type === 4 || type === undefined) {
        this.params3.start_time = parseInt(start / 1000);
        this.params3.end_time = parseInt(end / 1000);
      }
    },
    tabChange(tab) {
      this.$nextTick(() => {
        this.resetTimeParams();

        if (tab.label === '订单数') {
          if (this.orderTb) this.orderTb.dispose();
          if (this.orderPie) this.orderPie.dispose();
          this.orderTb = this.$echarts.init(document.getElementById('orderTb'));
          this.orderPie = this.$echarts.init(document.getElementById('orderPie'));

          this.GET_PriceStatistics();
          this.GET_PriceStatistics1();
        } else {
          if (this.moneyTb) this.moneyTb.dispose();
          if (this.moneyPie) this.moneyPie.dispose();
          this.moneyTb = this.$echarts.init(document.getElementById('moneyTb'));
          this.moneyPie = this.$echarts.init(document.getElementById('moneyPie'));

          this.GET_PriceStatistics2();
          this.GET_PriceStatistics3();
        }
      });
    },
    downZd() {
      let data = {...this.data}
      this.importLoading = true
      let response = []
      data.xAxis.forEach(item => {
        response.push({date: item})
      })
      data.series.data.forEach((item, index) => {
        response[index]['xz'] = item
      })
      let tHeaders = ['日期', '新增订单数']
      let filterVals = ['date', 'xz']
      handleDownload(response, tHeaders, filterVals, '新增订单')
      this.importLoading = false
    },
    downZd1() {
      let data = {...this.data1}
      this.importLoading1 = true
      let response = []
      data.xAxis.forEach(item => {
        response.push({date: item})
      })
      data.series.data.forEach((item, index) => {
        response[index]['xz'] = item
      })
      let tHeaders = ['日期', '消耗金额']
      let filterVals = ['date', 'xz']
      handleDownload(response, tHeaders, filterVals, '消耗金额')
      this.importLoading1 = false
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = parseInt(val[0] / 1000);
        this.params.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(1);
      }
      this.GET_PriceStatistics();
    },
    daterangeDateChange1(val) {
      if (val) {
        this.params1.start_time = parseInt(val[0] / 1000);
        this.params1.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(2);
      }
      this.GET_PriceStatistics1();
    },
    daterangeDateChange2(val) {
      if (val) {
        this.params2.start_time = parseInt(val[0] / 1000);
        this.params2.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(3);
      }
      this.GET_PriceStatistics2();
    },
    daterangeDateChange3(val) {
      if (val) {
        this.params3.start_time = parseInt(val[0] / 1000);
        this.params3.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(4);
      }
      this.GET_PriceStatistics3();
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2
      /** 图表刷新 */
      setTimeout(this.orderTb.resize)
      setTimeout(this.orderPie.resize)
      setTimeout(this.moneyTb.resize)
      setTimeout(this.moneyPie.resize)
    },

    typeChange() {
      // this.$nextTick(() => {
      // 	this.orderTb = this.$echarts.init(document.getElementById('orderTb'))
      // 	this.orderPie = this.$echarts.init(document.getElementById('orderPie'))
      // 	this.moneyTb = this.$echarts.init(document.getElementById('moneyTb'))
      // 	this.moneyPie = this.$echarts.init(document.getElementById('moneyPie'))
      // })
      // this.GET_PriceStatistics()
      this.countTableHeight()
    },

    GET_PriceStatistics() {
      API_buyAnyalysis.orderQuantity(this.params).then(response => {
        this.data = response

        // 临时数据
        // response.series.data = [2,3,1,2,7,2,1,0]
        // 临时数据

        this.orderTb.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px',
          },
          legend: {
            show: true,
            data: [{name: '新增订单数'}],
            top: '10px'
          },
          color: ['#7CB5EC'],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '新增订单数',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              name: '新增订单数',
              smooth: true,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1a43a9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ]),
                },
              },
              areaStyle: {normal: {}},
              symbolSize: 0,
              barWidth: 50,
            }
          ]
        })
      })
    },
    GET_PriceStatistics1() {
      API_buyAnyalysis.orderTopNum(this.params1).then(response => {

        if (response.length === 0) {
          response = [{
            order_num: 0,
            goods_num: 0,
            goods_name: '暂无数据',
            enterprise_price: 0,
          }];
        }

        response.forEach(item => {
          item.value = item.order_num;
          item.name = item.goods_name;
        });
        this.pageData.data = response
        this.orderPie.setOption(
          {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)',
              confine: true
            },
            series: [{
              name: 'TOP流量数据',
              type: 'pie',
              radius: '60%',
              center: ['50%', '50%'],
              data: response,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          }
        )
      })
    },
    GET_PriceStatistics2() {
      API_buyAnyalysis.orderMoney(this.params2).then(response => {
        this.data1 = response

        // 临时数据
        // response.series.data = [1,3,4,5,2,3,1,4]
        // 临时数据

        this.moneyTb.setOption({
          tooltip: {trigger: 'axis'},
          grid: {
            x: '50px',
            y: '80px',
            x2: '60px',
            y2: '30px',
          },
          legend: {
            show: true,
            data: [{name: '消耗金额'}],
            top: '10px'
          },
          color: ['#7CB5EC'],
          toolbox: {
            feature: {
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            name: '日期',
            type: 'category',
            boundaryGap: false,
            data: response.xAxis
          },
          yAxis: {
            name: '消耗金额',
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              type: 'line',
              data: response.series.data,
              name: '消耗金额',
              smooth: true,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1a43a9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ]),
                },
              },
              areaStyle: {normal: {}},
              symbolSize: 0,
              barWidth: 50,
            }
          ]
        })
      })
    },
    GET_PriceStatistics3() {
      API_buyAnyalysis.orderTopMoney(this.params3).then(response => {
        if (!response.length) {
          response = [{
            total_money: 0,
            goods_name: '暂无数据'
          }];
        }

        response.forEach(item => {
          item.value = item.total_money
          item.name = item.goods_name
        });

        this.pageData2.data = response;

        

        this.moneyPie.setOption(
          {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)',
              confine: true
            },
            series: [{
              name: 'TOP金额消耗',
              type: 'pie',
              radius: '60%',
              center: ['50%', '50%'],
              data: response,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          }
        )
      })
    }
  }
}
</script>

<style lang="scss">
.statistics-shop-active {
  background: #fff;
  border-radius: 4px;

  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__content {
    padding: 16px;
  }
}
</style>

